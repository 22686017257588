import React from "react";
import "./contactus.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Element } from "react-scroll";

const styles = {
  heading: {
    color: "var(--primary-dark)",
    fontWeight: "bold",
  },
  main: {},
  centerTitle: {
    display: "flex",

    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "column",
  },
};
class ContactUs extends React.Component {
  render() {
    return (
      <>
        <Element name="contact">
          <div className="container">
            <div className="text-center" style={styles.main}>
              <div className="row">
                <div className="col-md-4" style={styles.centerTitle}>
                  <h2 style={styles.heading}>Contact Us</h2>
                </div>
                <div className="col-md-8">
                  <div className="contact-card">
                    <a href="mailto:prabhsimran@globaltechhuntservices.in">
                      <FontAwesomeIcon className="icon" icon={faEnvelope} />
                      <span> prabhsimran@globaltechhuntservices.in</span>
                      <FontAwesomeIcon
                        className="click-icon"
                        icon={faArrowRight}
                      />
                    </a>
                    <a href="tel:+919560309959">
                      <FontAwesomeIcon className="icon" icon={faPhone} />
                      <span>+91-9560309959</span>
                      <FontAwesomeIcon
                        className="click-icon"
                        icon={faArrowRight}
                      />
                    </a>
                    <a href="https://www.linkedin.com/company/global-techhunt-services/">
                      <FontAwesomeIcon className="icon" icon={faLinkedin} />
                      <span>@global-techhunt-services</span>
                      <FontAwesomeIcon
                        className="click-icon"
                        icon={faArrowRight}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Element>
      </>
    );
  }
}

export default ContactUs;
