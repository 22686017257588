import React, { useContext, useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { Link, useLocation, useParams } from 'react-router-dom'; // Import useLocation hook
import './navbar.css';
import { JobsContext } from '../../context/jobContext';

const MyNavbar = () => {
  const location = useLocation(); // Get the current location
  const { jobId } = useParams(); // Get the jobId parameter from the URL
  const { jobs } = useContext(JobsContext);
  const [logoSrc, setLogoSrc] = useState("assets/brand-name-logo.png");

  useEffect(() => {
    if ((location.pathname.includes('/jobs/') && jobId) || (location.pathname.includes('/apply/'))) {
      setLogoSrc("../assets/brand-name-logo.png"); // Change the logo source if the route contains '/jobs/' and jobId exists
    } else {
      setLogoSrc("assets/brand-name-logo.png"); // Default logo source
    }
  }, [location.pathname, jobId]);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <Navbar expand="md" className='navbar'>
      <Navbar.Brand className='navbar-brand' onClick={scrollToTop}>
        <Link to="/">
          <img className='brand-name-logo-img' src={logoSrc} alt="Brand Logo" />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle className="toggle-icon" aria-controls="navbarNav" />
      <Navbar.Collapse id="navbarNav">
        <Nav className="mr-auto">
          <Link className='nav-link' to="/">Home</Link>
          {jobs ? <Link className='nav-link' to="/jobs">All Jobs</Link> : <></> }
          {location.pathname === '/' && (
            <>
              <Nav.Item>
                <ScrollLink
                  className="nav-link"
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Services
                </ScrollLink>
              </Nav.Item>
              <Nav.Item>
                <ScrollLink
                  className="nav-link"
                  to="industries"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Industries we serve
                </ScrollLink>
              </Nav.Item>
              <Nav.Item>
                <ScrollLink
                  className="nav-link"
                  to="client-reviews"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Reviews
                </ScrollLink>
              </Nav.Item>
              <Nav.Item>
                <ScrollLink
                  className="nav-link"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  About us
                </ScrollLink>
              </Nav.Item>
              <Nav.Item>
                <ScrollLink
                  className="nav-link"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Contact Us
                </ScrollLink>
              </Nav.Item>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MyNavbar;
