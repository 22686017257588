const JOBS_APPLIED_KEY = 'jobsApplied';

const getJobsApplied = () => {
    const jobsApplied = localStorage.getItem(JOBS_APPLIED_KEY);
    return jobsApplied ? JSON.parse(jobsApplied) : [];
};

const addJobApplied = (jobId) => {
    const jobsApplied = getJobsApplied();
    if (!jobsApplied.includes(jobId)) {
        jobsApplied.push(jobId);
        localStorage.setItem(JOBS_APPLIED_KEY, JSON.stringify(jobsApplied));
    }
};

const hasJobApplied = (jobId) => {
    const jobsApplied = getJobsApplied();
    return jobsApplied.includes(jobId);
};

export { getJobsApplied, addJobApplied, hasJobApplied };
