import React from "react";
import "./services_we_offer.css";
import { Element } from "react-scroll";
import data from '../../data/data';
import { useNavigate } from "react-router-dom"; 


const ServicesWeOffer =()=> {
  const navigate = useNavigate();

  const handleClick = (service) => {
    navigate("/info", { state: { service } }); 
  };
    return (
      <>
        <Element name="services">
          <div className="container">
            <div className="outerMain">
              <h2 className="text-center heading">Services We Offer</h2>
              <div className="text-center main row">
                {data.servicesWeOffer.map((service, index) => (
                  <div className="serviceOuter col-md-3" key={index}  onClick={() => handleClick(service)}>
                    <img src={"/assets/"+ service.imagePath} className="ref-img" />
                    <span className="serviceName">{service.name}</span>
                    <p className="serviceDescription">{service.description}</p>
                  </div>
                ))}

              </div>
            </div>
          </div>
        </Element>
      </>
    );
}

export default ServicesWeOffer;
