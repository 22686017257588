var data = {
  servicesWeOffer: [
    {
      name: "HR Consultant",
      description:
        "We are one of the well established name for providing different types of hr consultancy. We have achieved a lot of success as human resource agency located in Punjab. The hr consultancy services provided by us are the paradigm of knowledge and experience. \n We have achieved a successful place in the service industry because of the grade oriented services provided by us.",
      imagePath: "services_we_offer/hr.jpg",
    },

    {
      name: "Manpower Recruitment",
      description:
        "At Global Techhunt Services, our area of expertise lies in Manpower Recruitment. We have provided competent manpower to clients across different sectors like Sales & Marketing, Telecom/ Technology/ ISP, Manufacturing/ Operations, Construction, Tour & Travel, Automobile, Academic/ Teaching, Retail, Pharmaceuticals, Textiles, etc. \n With years of our existence, we have established a niche for ourselves in the Manpower Recruitment domain. We fully understand the need of quality manpower for a smooth running business. That is why; we provide quality manpower to organizations on a timely basis.",
      imagePath: "services_we_offer/manpower.jpg",
    },

    {
      name: "Placement Consultant",
      description:
        "Global Techhunt Services is a premier name in the placement sector of Punjab. The company operates from Punjab and we provide placement consultancy to job seekers - fresher and experienced professionals. A team of placement consultants is entrusted to provide regular updates concerning on-going placement drives, walk-ins, and interviews. Not just this, we also provide tips and guidance to secure a job.",
      imagePath: "services_we_offer/placement.jpg",
    },
  ],
  industriesWeServe: [
    {name: "BPO", imagePath: "industries/bpo.jpg"},
    {name: "Banking and Finance", imagePath: "industries/banking_finance.jpg"},
    {name:"Sales & Marketing", imagePath: "industries/sales_marketing.jpg"},
    {name:"Export & Import", imagePath: "industries/import_export.jpg"},
    {name:"Tour & Travel", imagePath: "industries/tour_travel.jpg"},
    {name:"Insurance", imagePath: "industries/insurance.jpg"},
    {name:"IT", imagePath: "industries/it.jpg"},
    {name:"Retail", imagePath: "industries/retail.jpg"},
  ],

  clientReviews: [
    {
      stars: 4,
      reviewBy: "Ajay",
      review:
        "Global TechHunt Services is the most professional recruitment agency I have ever come across. The whole team was communicative throughout and helped me with interviews. I got the answers to my questions quickly here. I had the best experience with Global TechHunt Services for my career development journey. I surely highly recommend the services of this agency to all.",
    },
    {
      stars: 4,
      reviewBy: "Mohit Kumar",
      review:
        "My experience with the team of this agency made me feel very satisfied. I had been looking for a good permanent job for a long time, and when I spoke to this placement consultant, it was the beginning of a great experience. The team at this agency was very professional and kept me informed on every step. Happy to recommend this organization to everybody.",
    },
    {
      stars: 5,
      reviewBy: "Pahuldeep Singh",
      review:
        "My experience with this placement agency was overwhelming, showed me the right direction for being successful.",
    },
    {
      stars: 4,
      reviewBy: "Rahul",
      review:
        "I've worked with a few recruiters before, but Global TechHunt Services is truly exceptional. They understood my needs and found me a great opportunity.",
    },
    {
      stars: 4,
      reviewBy: "Priya",
      review:
        "Global TechHunt Services helped me update my resume and prepare for interviews. I'm so grateful for their support during my job search.",
    },
    {
      stars: 5,
      reviewBy: "Vikram",
      review:
        "I wasn't sure what direction to take in my career. Global TechHunt Services' advisors helped me discover my strengths. Definitely recommend their services!",
    },
  ],
};

export default data;
