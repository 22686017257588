import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { JobsContext } from "../../context/jobContext";
import MyNavbar from "../navbar/navbar";
import {
  faBuilding,
  faCalendar,
  faClock,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import "./job_detail.css";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons/faShareAlt";

const JobDetailPage = () => {
  const { jobId } = useParams();
  const { jobs } = useContext(JobsContext);

  const job = jobs.find((job) => job.id === jobId);

  if (!job) {
    return (
      <>
        <MyNavbar />
        <div className="container">Invalid Job Id</div>
      </>
    );
  }

  const handleShare = async () => {
    const shareData = {
      title: `${job.role} at ${job.company}`,
      text: `Check out this job: ${job.role} at ${job.company}.`,
      url: window.location.href,
    };

    if (navigator.share) {
      try {
        await navigator.share(shareData);
        console.log("Job shared successfully");
      } catch (err) {
        console.error("Error sharing the job:", err);
      }
    } else {
      console.error("Web Share API not supported in this browser.");
    }
  };

  return (
    <>
      <MyNavbar />
      <div className="container">
        <h2 className="text-center mb-4">Job Details</h2>
        <div className="job-details">
          <div className="row">
            <div className="col-9">
              <h4>
                <strong>{job.company}</strong>
              </h4>
              <p>{job.role}</p>
              <strong>{job.number_of_openings} Openings</strong>
            </div>
            <div className="col-3 text-center">
              <div style={{ display: "flex", justifyContent: "end" }}>
                <button className="btn" onClick={handleShare}>
                  <FontAwesomeIcon icon={faShareAlt} />
                </button>
                <Link
                  style={{ display: "block" }}
                  className="btn btn-primary-dark apply-top-btn"
                  to={`/apply/${job.id}`}
                >
                  Apply Now
                </Link>
              </div>
            </div>
          </div>

          <p className="salary-stipend">
            {job.is_internship ? (
              <p>{job.stipend_per_month}/month</p>
            ) : (
              <p>{job.salary}</p>
            )}
            <p className="experience card-text mb-0">
              Experience: {job.experience}
            </p>
            <div className="job-type-section">
              <p
                className="card-text"
                style={{ position: "relative", top: "-12px" }}
              >
                <FontAwesomeIcon icon={faClock} />
                &nbsp;{job.duration}
              </p>
              <span style={{ position: "relative", top: "-12px" }}>
                {job.is_internship ? "Internship" : "Fulltime"}
              </span>
            </div>
          </p>

          <div className="row text-center">
            <div className="col-4">
              <p className="card-text">
                <FontAwesomeIcon icon={faCalendar} />
                &nbsp;
                {job.is_immediate_joiner
                  ? "Immediate"
                  : "Start: " + job.start_date}
              </p>
            </div>
            <div className="col-4">
              <p className="card-text">
                <FontAwesomeIcon icon={faBuilding} />
                &nbsp;{job.office_location}
              </p>
            </div>
            <div className="col-4">
              <p className="card-text">
                <FontAwesomeIcon icon={faLocationDot} />
                &nbsp;{job.work_mode}
              </p>
            </div>
          </div>
          <div className="job-description">
            <h4>Job Description</h4>
            <div dangerouslySetInnerHTML={{ __html: job.description }} />
          </div>
        </div>
        <div className="row apply-btn always-bottom">
          <div className="col-4 card-text text-center">
            <strong>Last Date</strong>
            <br />
            {job.last_date && job.last_date.trim() !== ""
              ? job.last_date.trim()
              : "---"}
          </div>
          <div className="col-4">
            <Link
              style={{ display: "block" }}
              className="btn btn-primary-dark"
              to={`/apply/${job.id}`}
            >
              Apply Now
            </Link>
          </div>
          <div className="col-4 text-center">
            <button className="btn btn-secondary-dark" onClick={handleShare}>
              <FontAwesomeIcon icon={faShareAlt} /> Share
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetailPage;
