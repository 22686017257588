import React from "react";
import "./home.css";
import { Element, Link } from "react-scroll";
import MyNavbar from "../navbar/navbar";

const styles = {
  main: {
    height: "100vh",
    // backgroundImage: "linear-gradient(to top, #e6f4f1, #a2dde3, #55c2e6, #00a3ec, #267ce7)",
    // backgroundImage: "linear-gradient(to top, #e6f4f1, #8ec5cc, #2e93b8, #005ea2, #00247b)"
    backgroundColor: "var(--primary-light)",
    // backgroundImage: "linear-gradient(to bottom, #c9a762, #bec085, #bcd5ae, #c9e6d5, #e6f4f1)"
  },
  heading: {
    color: "var(--golden)",
    fontWeight: "bold",
  },
  centeredContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // height: '100%', // Adjust this if needed
  },
  description: {
    color: "var(--primary-dark)",
    // fontSize:'1.2rem',
    fontWeight: "bold",
    textAlign: "justify",
  },
};
class Home extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Element name="home">
          <div style={styles.main}>
            <MyNavbar />
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 content">
                  <div className="col-8" style={styles.centeredContainer}>
                    <h2 style={styles.heading}>
                      Welcome to Global TechHunt Services
                    </h2>
                  </div>

                  <p style={styles.description}>
                    At Global TechHunt Services, we specialize in connecting top
                    talent with organizations in need. Our dedicated team of
                    experts works diligently to match qualified candidates with
                    open positions, streamlining the hiring process for both
                    employers and job seekers.
                  </p>

                  <Link
                    className="btn btn-primary"
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    About Us
                  </Link>
                </div>

                <div className="col-12 col-md-6 text-center d-none d-md-block">
                  <img
                    className="img-fluid home-logo"
                    src="/assets/home_logo.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </Element>
      </>
    );
  }
}

export default Home;
