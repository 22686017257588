import React from "react";
import "./client_reviews.css";
import data from "../../data/data";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Element } from "react-scroll";

const styles = {
  main: {
    backgroundColor: "var(--primary-light)",
    position: "relative",
    top: "0",
    borderRadius: "50px",
    padding: "30px",
    // color:'var(--light-violet)'
  },
  heading: {
    color: "var(--primary-light)",
    margin: "0 0 20px 0",
    fontWeight: "bold",
  },
};
function generateRatingStars(rating) {
    if (rating < 1 || rating > 5) {
      return null; // Return null for invalid ratings
    }
  
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <span key={i} className={i < rating ? "filled-star" : "empty-star"}>
          ★ 
        </span>
      );
    }
    return stars;
  }

class ClientReviews extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <Element name="client-reviews">

       
      <div className="container">
        <div className="outerMain" style={{padding: "30px 30px 0px 30px"}}>
          <h2 className="text-center" style={styles.heading}>
            Client Reviews
          </h2>
          <div className="clientReviewOuter">
            <Carousel className="carousel"
              width={"100%"}
              emulateTouch={true}
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              showArrows={false}
            //   centerMode={true}
            //   centerSlidePercentage={80}

              swipeable={true}
              autoPlay={true}
              renderCenterLeftControls={false}
              renderCenterRightControls={false}
            >
              {data.clientReviews.map((review, index) => (
                <div key={index} className="clientReview">
                    <div className="row">
                        <div className="col-md-6 rating-left">
                            <div className="rating-heading">
                                {review.stars}/5
                            </div>
                            <div>
                            {generateRatingStars(review.stars)}

                            </div>
                        </div>
                        <div className="col-md-6">
                        <p className="reviewText">{review.review}</p>
                  <span className="reviewBy">– {review.reviewBy}</span>
                        </div>
                    </div>
                  
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      </Element>
    );
  }
}

export default ClientReviews;
