import React from "react";
import "./about.css";
import { Element } from "react-scroll";
const styles = {
  heading: {
    color: "var(--primary-dark)",
    fontWeight: "bold",
  },
  main: {
    margin: "20px 0 30px 0",
  },

  description: {
    color: "var(--primary-dark)",
    textAlign: "justify"
  },
};
class About extends React.Component {
  render() {
    return (
      <Element name="about">
        <div className="container">
          <div style={styles.main} className="text-center">
            <h2 style={styles.heading}>About us</h2>
            <p style={styles.description}>
              <b>Global TechHunt Services</b> &nbsp;
              is one of the trusted job placement agencies in Amritsar (Punjab).
              The company serves organizations that are highly ambitious and
              determined to expand their business and excel in the sector. We
              offer excellent opportunities for job seekers and place every
              deserving candidate in their desired positions with a good salary
              package. Our main job is to bridge the gap between individuals
              seeking job opportunities and companies looking for great talents.
              
            </p>
          </div>
        </div>
      </Element>
    );
  }
}

export default About;
