import React from "react";
import "./footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEnvelope,
  faLocationDot,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import data from "../../data/data";

const Footer = () => {
  const navigate = useNavigate();

  const handleClick = (service) => {
    navigate("/info", { state: { service } });
  };
  return (
    <div className="footer">
      <div className="row footer-inner">
        <div className="col-md-4 text-center center-vertically">
          <div className="row">

          <div className="col-12">
            <span>Copyright © 2023. All rights reserved</span>
          </div>
          <div className="col-12">
            <span>Registration Number: UDYAM-PB-01-0070308</span>
          </div>
        </div>
          </div>
        <div className="col-md-4">
          <h5>Quick Contact</h5>

          <div className="footer-quicklinks">
            <FontAwesomeIcon className="icon" icon={faUser} />
            <span>Prabhsimran Kaur</span>
          </div>

          <div className="footer-quicklinks">
            <FontAwesomeIcon className="icon" icon={faLocationDot} />
            <span>Amritsar, Chandigarh, Gurgaon</span>
          </div>

          <a href="tel:+919560309959" className="footer-quicklinks">
            <FontAwesomeIcon className="icon" icon={faPhone} />
            <span>+91-9560309959</span>
          </a>

          <a
            href="mailto:prabhsimran@globaltechhuntservices.in"
            className="footer-quicklinks"
          >
            <FontAwesomeIcon className="icon" icon={faEnvelope} />
            <span>prabhsimran@globaltechhuntservices.in</span>
          </a>
        </div>

        <div className="col-md-4">
          <h5>Services we offer</h5>
          <a className="">
            {data.servicesWeOffer.map((service, index) => (
              <div
                className="footer-quicklinks"
                key={index}
                onClick={() => handleClick(service)}
              >
                <FontAwesomeIcon className="icon" icon={faArrowRight} />
                <span className="serviceName">{service.name}</span>
              </div>
            ))}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
