import React, { createContext, useState, useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";

export const JobsContext = createContext();

export const JobsProvider = ({ children }) => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      // Create a query against the collection.
      const q = query(collection(db, "jobs"), where("is_deleted", "==", false));
      
      const querySnapshot = await getDocs(q);
      const jobsList = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        if (data.last_date && data.last_date.toDate) {
          data.last_date = data.last_date.toDate().toLocaleDateString();
        } else {
          data.last_date = null; // or set it to "---" or any default value you prefer
        }
        data.start_date = data.start_date?.toDate().toLocaleDateString();
        return {
          id: doc.id,
          ...data,
        };
      });

      jobsList.sort((a, b) => b.created_at - a.created_at);
    
      setJobs(jobsList);
    };

    fetchData();
  }, []);

  return (
    <JobsContext.Provider value={{ jobs }}>
      {children}
    </JobsContext.Provider>
  );
};
