import "./App.css";

import Footer from "./components/footer/footer";
import Home from "./components/home/home";
import IndustriesWeServe from "./components/industries_we_serve/industries_we_serve";
import ContactUs from "./components/contactus/contactus";
import About from "./components/about/about";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import ServicesWeOffer from "./components/services_we_offer/services_we_offer";
import ClientReviews from "./components/client_reviews/client_reviews";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import InfoPage from "./components/info_page/info_page";
import JobsPage from "./components/jobs/jobs";
import AllJobsPage from "./components/all_jobs/all_jobs";
import JobDetailPage from "./components/job_detail/job_detail";
import { JobsProvider } from "./context/jobContext";
import JobApplicationForm from "./components/job_application_form/job_application_form";

function App() {
  return (
    <>
      <JobsProvider>
      <BrowserRouter>
        <Routes>
          <Route
            
            path="/"
            element={
              <>
                <a className="whatsapp-icon" href="https://api.whatsapp.com/send?phone=9560309959">
                  <FontAwesomeIcon style={{ height: "30px" }} icon={faWhatsapp} />
                </a>
                <Home />
                <JobsPage/>
                <ServicesWeOffer />
                <IndustriesWeServe />
                <ClientReviews />
                <About />
                <ContactUs />
                <Footer />
              </>
            }
          />
            <Route path="/info" element={<InfoPage />} />

            <Route path="/jobs" element={<AllJobsPage />} />
            <Route path="/jobs/:jobId" element={ <JobDetailPage />
          } />
          <Route path="/apply/:jobId" element={ <JobApplicationForm />
          } />
        </Routes>
      </BrowserRouter>
      </JobsProvider>
    </>
  );
}

export default App;
