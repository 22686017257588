import React, { useContext } from "react";
import "./jobs.css";
import { faBuilding, faCalendar, faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { Carousel } from "react-responsive-carousel";
import { JobsContext } from "../../context/jobContext";
import { Link } from "react-router-dom";

const JobsPage = () => {
  const { jobs } = useContext(JobsContext);

  if(!jobs){
    return (<></>);
  }

  return (
    <>
      <div className="container">
        <div className="outerMain">
          <h2 className="text-center heading">Job Openings</h2>
          <Carousel className="carousel carousel-jobs"
              width={"100%"}
              emulateTouch={true}
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              showArrows={false}
            //   centerMode={true}
            //   centerSlidePercentage={80}

              swipeable={true}
              autoPlay={true}
              renderCenterLeftControls={false}
              renderCenterRightControls={false}
            >
              {jobs.map((job) => (
                <Link className="card job-card" to={`/jobs/${job.id}`}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-9">
                        <h5 className="card-title">
                          <strong>{job.company}</strong>
                        </h5>
                        <h6 className="card-subtitle mb-2">{job.role}</h6>
                        <p className="card-text">
                      <strong>{job.number_of_openings} Openings</strong>
                    </p>
                      </div>
                      <div className="col-3 text-center">
                        <p className="job-type">
                          {job.is_internship ? "Internship" : "Fulltime"}
                        </p>
                        {job.is_internship ?  <p className="card-text" style={{position: "relative",top: "-12px"}}>
                        <FontAwesomeIcon icon={faClock} />
                            &nbsp;{job.duration}
                        </p> : ""}
                      </div>
                    </div>
                    {/* <p className="card-text"><strong>Description:</strong> {job.description}</p> */}

                    <p className="salary-stipend">
                      {job.is_internship ? <p>{job.stipend_per_month}/month</p> : <p>{job.salary}</p>}
                      <p className="experience card-text">Experience: {job.experience}</p>
                    </p>

                   
                    <div className="row text-center">
                      <div className="col-4">
                   
                          <p className="card-text">
                            <FontAwesomeIcon icon={faCalendar} />
                            &nbsp;{job.is_immediate_joiner? "Immediate" : "Start: "+ job.start_date}
                          </p>
                       
                      </div>
                      <div className="col-4">
                        <p className="card-text">
                          <FontAwesomeIcon icon={faBuilding} />
                          &nbsp;{job.office_location}
                        </p>
                      </div>
                      <div className="col-4">
                        <p className="card-text">
                          <FontAwesomeIcon icon={faLocationDot} />
                          &nbsp;{job.work_mode}
                        </p>
                      </div>
                    </div>

                    <div className="row apply-btn">
                      <div className="col-4 card-text text-center">
                        <strong>Last Date</strong>
                        <br/>
                        {job.last_date && job.last_date.trim() !== "" ? job.last_date.trim() : "---"}
                      </div>
                      <div className="col-8">
                      <a
                      style={{display: "block"}}
                        className="btn btn-primary-dark"
                        // href={job.apply_link}
                        href="https://docs.google.com/forms/d/e/1FAIpQLScTqnLm0b0nGlu7u_adkfewZx3yyOazCfYDHJmLxw4Ehi5NLQ/viewform"
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        Apply Now
                      </a>
                        </div>
                    </div>
                  </div>
                </Link>
              ))}
              </Carousel>
          <Link className="view-all-jobs" to="jobs" >
            View all Jobs
          </Link>
        </div>
      </div>
    </>
  );
};
export default JobsPage;
