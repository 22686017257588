import React from "react";
import "./industries_we_serve.css";
import { Element } from "react-scroll";
import data from '../../data/data';
const styles = {
  outerMain:{
    backgroundColor: "var(--primary-dark)",
    borderRadius: "60px",
    padding:"30px",
    boxShadow: "0 10px 10px grey",
     position: "relative",
    top: "-35px",
    marginBottom: "40px"
  },
  main: {
    backgroundColor: "var(--primary-light)",
    position: "relative",
    top: "0",
    borderRadius: "50px",
    padding: "30px",
    // color:'var(--light-violet)'
  },
  heading: {
    color: "var(--primary-light)",
    margin: "0 0 20px 0",
    fontWeight: "bold",
  },
  description: {
    color: "var(--primary-dark)",
  },
};

class IndustriesWeServe extends React.Component {
  render() {
    return (
      <>
        <Element name="industries">
          <div className="container">
            <div style={styles.outerMain}>
            <h2 style={styles.heading} className="text-center">Industries We Serve</h2>
              <div style={styles.main} className="text-center">
                <p style={styles.description}>
                  Global TechHunt Services proudly serves a diverse array of
                  industries, ensuring excellence and innovation across various
                  sectors, which include but are not limited to:
                </p>
                <div className="typesOfIndustries">
                  {data.industriesWeServe.map((industry, index) => (
                    <div className="industriesOuter">
                    <img src={"/assets/"+ industry.imagePath} className="ref-img" />
                      <span key={index}>{industry.name}</span>
                    </div>
                  ))}
                </div>
                <p style={styles.description}>
                  Our adaptable approach allows us to tailor our services to
                  meet the unique requirements of each sector, providing
                  comprehensive solutions for your business needs.
                </p>
              </div>
            </div>
          </div>
        </Element>
      </>
    );
  }
}

export default IndustriesWeServe;
