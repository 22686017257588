import React, { useContext, useState } from 'react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import MyNavbar from '../navbar/navbar';
import { useParams } from 'react-router-dom';
import { JobsContext } from '../../context/jobContext';
import './job_application_form.css';
import { addJobApplied, hasJobApplied } from '../../service/localstorage_service';

const JobApplicationForm = () => {
    const { jobId } = useParams();
    const { jobs } = useContext(JobsContext);

    const job = jobs.find((job) => job.id === jobId);
    const [formData, setFormData] = useState({
        jobPostingId: jobId,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        address: '',
        state: '',
        city: '',
        dateOfBirth: '',
        linkedInProfile: '',
        availableStartDate: '',
        yearsOfExperience: '',
        currentJobTitle: '',
        currentEmployer: '',
        resumeUrl: '',
        additionalComments: '',
        willingToRelocate: false,
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const validateForm = () => {
      const newErrors = {};
      if (!formData.firstName) newErrors.firstName = 'First Name is required';
      if (!formData.lastName) newErrors.lastName = 'Last Name is required';
      if (!formData.email) newErrors.email = 'Email is required';
      if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) newErrors.email = 'Email is invalid';
      if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone Number is required';
      if (!/^\d{10}$/.test(formData.phoneNumber)) newErrors.phoneNumber = 'Phone Number must be of 10 digits';
      if (!formData.address) newErrors.address = 'Address is required';
      if (!formData.state) newErrors.state = 'State is required';
      if (!formData.city) newErrors.city = 'City is required';
      if (!formData.dateOfBirth) newErrors.dateOfBirth = 'Date of Birth is required';
      if (!formData.yearsOfExperience) newErrors.yearsOfExperience = 'Years of Experience is required';
      if (!formData.resumeUrl) newErrors.resumeUrl = 'Resume URL is required';
      if (!/^https?:\/\/.*$/.test(formData.resumeUrl)) newErrors.resumeUrl = 'Resume URL is invalid';

      if (formData.yearsOfExperience > 0) {
          if (!formData.currentEmployer) newErrors.currentEmployer = 'Current Employer is required';
          if (!formData.currentJobTitle) newErrors.currentJobTitle = 'Current Job Title is required';
      }

      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
  };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            alert('Please fix the errors in the form');
            return;
        }
        try {
            await addDoc(collection(db, 'job_application'), {
                ...formData,
                yearsOfExperience: formData.yearsOfExperience<0 ? '0': formData.yearsOfExperience,
                dateOfBirth: formData.dateOfBirth ? new Date(formData.dateOfBirth) : null,
                availableStartDate: formData.availableStartDate ? new Date(formData.availableStartDate) : null,
                appliedAt: Timestamp.fromDate(new Date()),
            });            
            addJobApplied(jobId);
            alert('Application submitted successfully!');
            setFormData({
                jobPostingId: jobId,
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                address: '',
                state: '',
                city: '',
                dateOfBirth: '',
                linkedInProfile: '',
                availableStartDate: '',
                yearsOfExperience: '',
                currentJobTitle: '',
                currentEmployer: '',
                resumeUrl: '',
                additionalComments: '',
                willingToRelocate: false,
            });
            setErrors({});
        } catch (error) {
            console.error('Error submitting application: ', error);
            alert('Failed to submit application.');
        }
    };

    if(hasJobApplied(jobId)){
      return (
        <>
        <MyNavbar/>
        <div className='container'>

          <div className='job-applying-to-info'>
                <h4>You're applying for</h4>
                <span>{job && `${job.company}, ${job.role}`}</span>
              </div>

              <p className='job-already-applied'>
                You already applied to this Job Opening
              </p>                      

        </div>
        </>
      );
    }

    return (
        <>
            <MyNavbar />
            <div className='container'>
              <div className='job-applying-to-info'>
                <h4>You're applying for</h4>
                <span>{job && `${job.company}, ${job.role}`}</span>
              </div>
                <form onSubmit={handleSubmit}>
                    <div className={errors.firstName ? 'form-outer error-outer': 'form-outer'}>
                        {errors.firstName && <div className="error">{errors.firstName}</div>}
                        <label className='form-label'>First Name*</label>
                        <input className='my-input form-control' type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
                    </div>
                    <div className={errors.lastName ? 'form-outer error-outer': 'form-outer'}>
                        {errors.lastName && <div className="error">{errors.lastName}</div>}
                        <label className='form-label'>Last Name*</label>
                        <input className='my-input form-control' type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
                    </div>
                    <div className={errors.email ? 'form-outer error-outer': 'form-outer'}>
                        {errors.email && <div className="error">{errors.email}</div>}
                        <label className='form-label'>Email</label>
                        <input className='my-input form-control' type="email" name="email" value={formData.email} onChange={handleChange} />
                    </div>
                    <div className={errors.phoneNumber ? 'form-outer error-outer': 'form-outer'}>
                        {errors.phoneNumber && <div className="error">{errors.phoneNumber}</div>}
                        <label className='form-label'>Phone Number</label>
                        <input className='my-input form-control' type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
                    </div>
                    <div className={errors.address ? 'form-outer error-outer': 'form-outer'}>
                        {errors.address && <div className="error">{errors.address}</div>}
                        <label className='form-label'>Address</label>
                        <input className='my-input form-control' type="text" name="address" value={formData.address} onChange={handleChange} />
                    </div>
                    <div className={errors.state ? 'form-outer error-outer': 'form-outer'}>
                        {errors.state && <div className="error">{errors.state}</div>}
                        <label className='form-label'>State</label>
                        <input className='my-input form-control' type="text" name="state" value={formData.state} onChange={handleChange} />
                    </div>
                    <div className={errors.city ? 'form-outer error-outer': 'form-outer'}>
                        {errors.city && <div className="error">{errors.city}</div>}
                        <label className='form-label'>City</label>
                        <input className='my-input form-control' type="text" name="city" value={formData.city} onChange={handleChange} />
                    </div>
                    <div className={errors.dateOfBirth ? 'form-outer error-outer': 'form-outer'}>
                        {errors.dateOfBirth && <div className="error">{errors.dateOfBirth}</div>}
                        <label className='form-label'>Date of Birth</label>
                        <input className='my-input form-control' type="date" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange} />
                    </div>
                    <div className={errors.linkedInProfile ? 'form-outer error-outer': 'form-outer'}>
                        {errors.linkedInProfile && <div className="error">{errors.linkedInProfile}</div>}
                        <label className='form-label'>LinkedIn Profile</label>
                        <input className='my-input form-control' type="url" name="linkedInProfile" value={formData.linkedInProfile} onChange={handleChange} />
                    </div>
                    <div className={errors.availableStartDate ? 'form-outer error-outer': 'form-outer'}>
                        {errors.availableStartDate && <div className="error">{errors.availableStartDate}</div>}
                        <label className='form-label'>Available Start Date</label>
                        <input className='my-input form-control' type="date" name="availableStartDate" value={formData.availableStartDate} onChange={handleChange} />
                    </div>
                    <div className={errors.yearsOfExperience ? 'form-outer error-outer': 'form-outer'}>
                        {errors.yearsOfExperience && <div className="error">{errors.yearsOfExperience}</div>}
                        <label className='form-label'>Years of Experience</label>
                        <input className='my-input form-control' type="number" name="yearsOfExperience" value={formData.yearsOfExperience} onChange={handleChange} />
                    </div>
                    <div className={errors.currentEmployer ? 'form-outer error-outer': 'form-outer'}>
                        {errors.currentEmployer && <div className="error">{errors.currentEmployer}</div>}
                        <label className='form-label'>Current Employer</label>
                        <input className='my-input form-control' type="text" name="currentEmployer" value={formData.currentEmployer} onChange={handleChange} />
                    </div>
                    <div className={errors.currentJobTitle ? 'form-outer error-outer': 'form-outer'}>
                        {errors.currentJobTitle && <div className="error">{errors.currentJobTitle}</div>}
                        <label className='form-label'>Current Job Title</label>
                        <input className='my-input form-control' type="text" name="currentJobTitle" value={formData.currentJobTitle} onChange={handleChange} />
                    </div>
                    
                    <div className={errors.resumeUrl ? 'form-outer error-outer': 'form-outer'}>
                        {errors.resumeUrl && <div className="error">{errors.resumeUrl}</div>}
                        <label className='form-label'>Resume URL</label>
                        <input className='my-input form-control' type="url" name="resumeUrl" value={formData.resumeUrl} onChange={handleChange} />
                    </div>
                    <div className={errors.additionalComments ? 'form-outer error-outer': 'form-outer'}>
                        {errors.additionalComments && <div className="error">{errors.additionalComments}</div>}
                        <label className='form-label'>Additional Comments</label>
                        <textarea className='my-input form-control' name="additionalComments" value={formData.additionalComments} onChange={handleChange} />
                    </div>
                    <div className="form-outer form-checkbox">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="willingToRelocate" name="willingToRelocate" checked={formData.willingToRelocate} onChange={handleChange} />
                            <label className="form-check-label" htmlFor="willingToRelocate">
                                Willing to Relocate
                            </label>
                        </div>
                    </div>
                    <div className='submit-btn-outer'>
                        <button className='btn btn-primary-dark form-submit-btn' type="submit">Submit Application</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default JobApplicationForm;
