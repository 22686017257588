import React from "react";
import "./info_page.css";
import { useLocation } from "react-router-dom";
import MyNavbar from "../navbar/navbar";
import ContactUs from "../contactus/contactus";

const styles = {
  heading: {
    color: "var(--primary-dark)",
    margin: "0 0 20px 0",
    fontWeight: "bold",
  },
  description: {
    textAlign: "justify",
  },
};
const InfoPage = () => {
  const location = useLocation();
  const service = location.state?.service;

  return (
    <>
      <MyNavbar />
      <div className="container">
        <br />
        {service && (
          <>
            <h2 style={styles.heading}>{service.name}</h2>
            <p style={styles.description}>{service.description}</p>
          </>
        )}
        <div className="row">
          <div className="col-md-6">
        <h2 style={styles.heading} className="text-center">Connect with us</h2>
            <iframe
              className="googleForm"
              src="https://docs.google.com/forms/d/e/1FAIpQLScTqnLm0b0nGlu7u_adkfewZx3yyOazCfYDHJmLxw4Ehi5NLQ/viewform?embedded=true"
            >
              Loading…
            </iframe>
          </div>
          <div className="col-md-6 center-me">
            <ContactUs />
          </div>
        </div>
      </div>
    </>
  );
};
export default InfoPage;
